import React, { Component } from 'react'
import Board from '../board/Board'
import map from 'lodash/map'
import ScoreBoard from '../score-board/ScoreBoard'
import { updateScoreboard } from '../../client/request/post/postRequest'

class MainInterface extends Component {
    constructor(props) {
        super(props)
        this.state = {
            boardType: 5,
            chooseBoardType: false,
            interest: 0,
            movementHistory: [],
            place: false,
            resetGame: false,
            scoreAdded: false,
            startGame: false,
            totalMoneyAvailable: 0,
            totalMoney: 0,
            totalMove: 15
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { startGame, place, chooseBoardType, scoreAdded } = this.state
        const main = document.getElementsByClassName('main')[0]
        if (prevState.startGame !== startGame && startGame) {
            this.generateInterest(5, 25)
        }
        if ((prevState.place !== place && place) || (prevState.chooseBoardType !== chooseBoardType && chooseBoardType)) {
            return map(main.children, child => {
                if (child.className !== 'main-modal') {
                    child.style.filter = 'blur(3px)'
                }
            })
        }
        if (prevState.scoreAdded !== scoreAdded && scoreAdded) {
            setTimeout(() => this.setState({ scoreAdded: false }), 3000)
        }

        
        return map(main.children, child => child.style.filter = '')
    }

    generateInterest = (min, max) => {
        const interest = Math.floor(Math.random() * (max - min) + min)
        return this.setState({ interest })
    }

    handleBoardData = (data) => {
        if (data.type === 'startGame') {
            return this.setState({ place: true })
        }

        if (data.type === 'money') {
            return this.setState({
                totalMoney: data.money
            })
        }

        if (data.type === 'moneyAvailable') {
            return this.setState({
                totalMoneyAvailable: data.totalMoneyAvailable
            })
        }

        if (data.type === 'movement') {
            return this.setState((prevState) => ({
                movementHistory: [
                    ...prevState.movementHistory,
                    data.movementHistory
                ]
            }))
        }

        if (data.totalMove === 0) {
            this.handleResetGame()
        }

        return this.setState({
            totalMove: data.totalMove
        })
    }

    handleResetGame = () => {
        const { boardType, interest, totalMoney, totalMoneyAvailable, movementHistory } = this.state
        const totalEarning = Math.floor(totalMoney + totalMoney * interest/100)
        const scoreData = {
            board_type: boardType,
            interest_rate: interest,
            movement_history: `${movementHistory}`,
            total_money_available: totalMoneyAvailable,
            total_money_found: totalMoney,
            total_money_earn: totalEarning
        }
        updateScoreboard(scoreData, this)
        return this.setState({
            place: true,
            resetGame: true,
            startGame: false
        })
    }

    handleStartGame = (e, choice) => {
        e.preventDefault()
        if (choice === 'yes') {
            return this.setState({
                chooseBoardType: true,
                interest: 0,
                place: false,
                totalMoney: 0
            })
        }
        return this.setState({ place: false })
    }

    handleChooseBoardType = (e, choice) => {
        e.preventDefault()
        return this.setState({
            boardType: choice,
            chooseBoardType: false,
            resetGame: false,
            startGame: true,
            totalMove: 15
        })
        
    }

    render() {
        const { totalMoney, totalMove, place, interest, startGame, boardType, chooseBoardType, resetGame, scoreAdded } = this.state
        const totalEarning = Math.floor(totalMoney + totalMoney * interest/100)
        return (
            <div className='main'>
                <span className='main-title'>Robot Money Collector</span>
                <div className='main-board'>
                    <div className='main-board-header'>
                        <span className='main-board-header-place' onClick={() => this.setState({ place: true })}>Spacebar to move, arrow to change direction</span>
                        <span className='main-board-header-move'>total move: {totalMove}</span>
                    </div>
                    <Board
                        boardData={this.handleBoardData}
                        boardType={boardType}
                        finishGame={this.handleResetGame}
                        startGame={startGame}
                    />

                </div>
                <ScoreBoard
                    earning={totalEarning}
                    interest={interest}
                    money={totalMoney}
                />
                {interest > 0 &&
                    <a href='/' className='main-reset' onClick={(e) => e.preventDefault()}>
                        <button onClick={this.handleResetGame}>Restart Game</button>
                    </a>
                }
                {scoreAdded &&
                    <span>Score Added✔</span>
                }
                {place &&
                    <div className='main-modal'>
                        {resetGame &&
                            <span className='main-modal-title'>Yay! you earn ${new Intl.NumberFormat().format(totalEarning)}</span>
                        }
                        <span className='main-modal-title'>{resetGame ? 'Restart' : 'Start'} Game?</span>
                        <div className='main-modal-choice'>
                            <a href='/' className='main-modal-choice-child' onClick={(e) => this.handleStartGame(e, 'yes')}>Yes</a>
                            <a href='/' className='main-modal-choice-child' onClick={(e) => this.handleStartGame(e, 'no')}>No</a>
                        </div>
                    </div>
                }
                {chooseBoardType &&
                    <div className='main-modal'>
                        <span className='main-modal-title'>Choose Board Type</span>
                        <div className='main-modal-choice'>
                            <a href='/' className='main-modal-choice-child' onClick={(e) => this.handleChooseBoardType(e, 3)}>3</a>
                            <a href='/' className='main-modal-choice-child' onClick={(e) => this.handleChooseBoardType(e, 4)}>4</a>
                            <a href='/' className='main-modal-choice-child' onClick={(e) => this.handleChooseBoardType(e, 5)}>5</a>
                            <a href='/' className='main-modal-choice-child' onClick={(e) => this.handleChooseBoardType(e, 6)}>6</a>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default MainInterface