import qs from 'qs'
import { get, post } from './auth.service'

const stringify = qs.stringify

export const getMethod = (url, query = {}) => {
    const qs = stringify(query)
    return fetch(`${url}?${qs}`, get)
}
export const postMethod = (url, body) => {
    const requestBody = JSON.stringify(body)
    return fetch(`${url}`, {
        ...post,
        body: requestBody
    })
}