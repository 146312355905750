import React, { Component } from 'react'

class ScoreBoard extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { money, interest, earning } = this.props
        return (
            <div className='scoreboard'>
                <span className='scoreboard-title'>Your Robot Earning</span>
                <div className='scoreboard-detail'>
                    <div className='scoreboard-detail-child'>
                        <span className='scoreboard-detail-child-number'>${new Intl.NumberFormat().format(money)}</span>
                        <span className='scoreboard-detail-child-title'>Money Found</span>
                    </div>
                    <div className='scoreboard-detail-child'>
                        <span className='scoreboard-detail-child-number'>{interest}%</span>
                        <span className='scoreboard-detail-child-title'>Interest Rate</span>
                    </div>
                    <div className='scoreboard-detail-child'>
                        <span className='scoreboard-detail-child-number'>${new Intl.NumberFormat().format(earning)}</span>
                        <span className='scoreboard-detail-child-title'>Total Money</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default ScoreBoard