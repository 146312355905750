import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render () {
        return (
            <div className='container'>
                <section className='container'>{React.cloneElement(this.props.children)}</section>
                <div className='stub' />
            </div>
        )
    }

}

export default withRouter(Layout)
