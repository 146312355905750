import React, { Component } from 'react'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import shuffle from 'lodash/shuffle'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import robotImage from '../../assets/images/robot.png'

class Board extends Component {
    constructor(props) {
        super(props)
        this.state = {
            boxesWithMoney: [],
            robotPosition: 1,
            robotDirection: 'down',
            robotRotation: 0,
            totalMoney: 0,
            totalMove: 15
        }
    }

    componentDidMount = () => {
        const { boardType } = this.props
        const board = document.getElementsByClassName('board')[0]
        const boards = document.getElementsByClassName('board-row')
        board.style.height = `${board.offsetWidth}px`
        map(boards, b => b.style.height = `${board.offsetWidth/boardType}px`)

        const robot = document.getElementsByClassName('robot')[0]
        robot.style.height = `${board.offsetWidth/(boardType+1)}px`
        robot.focus()
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { startGame, boardType } = this.props
        const board = document.getElementsByClassName('board')[0]

        if (prevProps.startGame !== startGame && !startGame) {
            this.handleReset()
        }

        if (prevProps.startGame !== startGame && startGame) {
            const boards = document.getElementsByClassName('board-row')
            board.style.height = `${board.offsetWidth}px`
            map(boards, b => b.style.height = `${board.offsetWidth/boardType}px`)
    
            const boxIds = shuffle(map([...Array(Math.pow(boardType, 2) - 1).keys()], boxId => boxId + 1))
    
            map([...Array(boardType)], (board, id) => this.generateMoney(500, 20000, boxIds[id]))
            this.setState({ robotPosition: 1, robotDirection: 'down' })
        }

        const robot = document.getElementsByClassName('robot')[0]
        robot.style.height = `${board.offsetWidth/(boardType+1)}px`
        robot.focus()
    }

    generateMoney = (minMoney, maxMoney, boxId) => {
        const money = Math.floor(Math.random() * (maxMoney - minMoney) + minMoney)
        return this.setState((prevState) => ({
                boxesWithMoney: [
                    ...prevState.boxesWithMoney,
                    {
                        id: boxId,
                        money
                    }
                ]
            })
        )
    }

    handleReset = () => {
        this.setState({
            boxesWithMoney: [],
            robotRotation: 0,
            totalMoney: 0,
            totalMove: 15
        })
    }

    handleRobotDirection = (e) => {
        const { startGame } = this.props
        let direction = ''
        let rotate = 0

        if (!startGame) {
            return this.handleSendDataUp({ type: 'startGame' })
        }

        if (includes([32, 37, 38, 39, 40], e.keyCode)) {
            e.preventDefault()
        }

        if (e.keyCode === 32) {
            return this.handleRobotMovement(e)
        }

        switch (e.keyCode) {
            case 37:
                direction = 'left'
                rotate = 90
                break
            case 38:
                direction = 'up'
                rotate = 180
                break
            case 39:
                direction = 'right'
                rotate = 270
                break
            case 40:
                direction = 'down'
                rotate = 0
                break
            default:
                break
        }
        return this.setState({
            robotDirection: direction,
            robotRotation: rotate
        })
    }

    handleRobotMovement = (e) => {
        const { boardType, finishGame } = this.props
        const { robotPosition, robotDirection, boxesWithMoney, totalMoney, totalMove } = this.state
        let newPosition = 0
        const newTotalMove = totalMove - 1
        
        if (e.keyCode === 32) {
            switch (robotDirection) {
                case 'left':
                    newPosition = robotPosition - 1
                    break
                case 'up':
                    newPosition = robotPosition - boardType
                    break
                case 'right':
                    newPosition = robotPosition + 1
                    break
                case 'down':
                    newPosition = robotPosition + boardType
                    break
                default:
                    break
            }
        }

        this.handleSendDataUp({ totalMove: newTotalMove, type: 'move' })
        
        if (newPosition <= Math.pow(boardType, 2) && newPosition >= 1) {
            this.handleSendDataUp({ movementHistory: newPosition, type: 'movement' })
            const unDiscoveredMoneyBox = filter(boxesWithMoney, box => box.id !== newPosition-1)
            const discoveredMoneyBox = filter(boxesWithMoney, box => box.id === newPosition-1)[0]
            let money = totalMoney
            if (!isEmpty(discoveredMoneyBox)) {
                money += discoveredMoneyBox.money
                if (isEmpty(unDiscoveredMoneyBox)) {
                    this.setState({ robotPosition: newPosition })
                    this.handleSendDataUp({ money, type: 'money' })
                    return finishGame()
                }
                this.handleSendDataUp({ money, type: 'money' })
            }

            const totalMoneyAvailable = reduce(unDiscoveredMoneyBox, (sum, box) => sum + box.money, 0)
            this.handleSendDataUp({ totalMoneyAvailable, type: 'moneyAvailable' })

            return this.setState({
                robotPosition: newPosition,
                boxesWithMoney: unDiscoveredMoneyBox,
                totalMoney: money,
                totalMove: newTotalMove
            })
        }
    }

    handleSendDataUp = (data) => {
        const { boardData } = this.props
        boardData(data)

    }

    renderSquare(i) {
        const { robotPosition, robotRotation, boxesWithMoney } = this.state
        return (
          <div className='board-column' key={i} >
              {robotPosition-1 === i &&
                <img
                    alt='robot'
                    autoFocus
                    className='robot'
                    onKeyDown={this.handleRobotDirection}
                    src={robotImage}
                    style={{ transform: `rotate(${robotRotation}deg)` }}
                    tabIndex={0}
                />
              }
              {
                map(boxesWithMoney, box => {
                    if (box.id === i && box.id !== robotPosition-1) {
                        return (
                            <div
                                autoFocus className='money'
                                onKeyDown={this.handleRobotDirection}
                                tabIndex={0}
                            >
                                {`$${new Intl.NumberFormat().format(box.money)}`}
                            </div>
                        )
                    }
                })
              }
          </div>
        );
      }
    
    render() {
        const { boardType } = this.props
        const self = this
        return (
            <div className='board'>
            {map([...Array(boardType)], (row, rowIdx) => { 
                return (
                    <div className='board-row' key={rowIdx}>
                        {map([...Array(boardType)], (col, colIdx) => {
                            return self.renderSquare((boardType * rowIdx) + colIdx)
                        })}
                    </div>
                );
            })}
            </div>
        );  
    }
}

export default Board