import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import MainInterface from './components/main-interface/MainInterface'
import Layout from './Layout'

import './layout.scss'

const LayoutContainer = () => (
    <Layout>
        <Switch>
            <Route component={MainInterface} exact path='/' />
        </Switch>
    </Layout>
)

const DocumentAndLayoutContainer = () => (
    <Switch>
        <Route component={LayoutContainer} path='/' />
    </Switch>
)

const RouteHandler = () => (
    <BrowserRouter>
        <Route component={DocumentAndLayoutContainer} path='/' />
    </BrowserRouter>
)

export default RouteHandler
